
.link-tree-button-container {
  margin: 1em auto;
  max-width: 90%;

}

.link-tree-button-link {
  color: #eee;
  text-align: center;
  text-decoration: none;
}

.link-tree-button-container .link-tree-button-text {
  color: #eee;
  font-family: 'Antic Slab', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
