@import url(https://fonts.googleapis.com/css2?family=Antic+Slab&family=Maven+Pro:wght@900&display=swap);
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div {
  transition: all .5s;
}

/** listentobrew css below */
body {
  margin: auto;
  background-image: url("/images/minor_field_clouds.JPG");
  background-size: cover;
  background-color: #222222;

  overflow: hidden;
}

@media only screen and (min-width: 800px) {
  div#root {
    background-image: linear-gradient(to right, transparent 0%, transparent 12%, rgba(255,255,255,.4) 30%, rgba(255,255,255,.4) 70%, transparent 88%, transparent 100%);
  }
}
@media only screen and (max-width: 800px) {
  div#root {
    background-color: rgba(255,255,255,.25);
  }
}

.header,
.app-body {
  min-width: 16em;
  width: 90vw;
  max-width: 400px;
  margin: auto;
  text-align: center;
}

.header {
  height: 35vh;
  padding-top: 1em;
}

.header-image {
  height: 20vh;
  border-radius: 100px;
  border: 3px solid #d9cfe6;
  box-shadow: 0px 0px 8px black;

  margin-top: .7em;
  margin-bottom: -.4em;
}

.header .title {
  font-family: 'Maven Pro', sans-serif;
  font-size: 7vh;
  color: #d9cfe6;
  text-shadow: -1px 0px 6px black;
}

.title {
  margin: 3vh auto;
  font-size: 4vh;
  line-height: 5vh;
  color: #222;
  font-family: monospace;
}

p {
  color: #222;
  font-family: 'Antic Slab', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1.8em;
  padding: .5em;
}

.app-body {
  overflow-y: auto;
  height: 60vh;
  padding-top: .5em;

  mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 75%, transparent 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 5%, black 75%, transparent 100%);
}

/** generics */
a {
  color: #eee;
}
a:visited {
  color: #ddd;
}

.link-row, .row {
  margin: 1em;
}

.row {
  display: flex;
}

.border {
  border: 1px solid #222;
  border-radius: 5px;

  box-shadow: black 0px 0px 10px -7px;

  margin-top: .25em;
  margin-bottom: .25em;

  transition: background-color .65s,
              box-shadow .35s;

  background-color: rgb(196 204 214 / .35);
}

.border:hover {
  background-color: rgb(196 204 214 / .55);
  box-shadow: black 0px -1px 10px -5px;
}

.has-title .border {
  background-color: rgb(196 204 214 / .5);
  box-shadow: black 0px -1px 10px -3px;
}

.border.selected,
.has-title .border:hover {
  background-color: rgb(196 204 214 / .6);
  box-shadow: black 0px -1px 10px -1px;
}

.border.button {
  cursor: pointer;
  margin: 1em auto;
  max-width: 90%;
  transition: width 5s;
}

.border.button.slid-left {
  width: 30%;
  display: flex;
}

.button p {
  padding: .85em .5em;
}

.copyright {
  color: #444;
  font-size: .75em;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  line-height: 5em;
}

.invisible {
  color: #222;
}
.hidden {
  display: none !important;
}

.playlist-link-button {
  font-size: 1.5em;
  padding: 1em;
  margin: 1em 0.5em;
}

.expandable .expandable-children {
  height: 5em;
}

.droptober-button.expandable-container,
.expandable-children {
  display: flex;
  flex-flow: row;
}

.row,
.link-row,
.social-row {
  text-align: center;
  margin: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;

  flex-wrap: wrap;
  /* mask-image: linear-gradient(to right, transparent 0%, black 5%, black 75%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%, black 75%, transparent 100%); */
}

.social-row-icon {
  font-size: 1.25em;
  text-align: center;
  min-width: 81px;
}

.link-row .button {
  display: flex;
  align-self: center;
  margin-right: 1em;
}

.link-row.has-title {
  max-width: 70%;
}

.row-title {
  vertical-align: bottom;
  justify-content: flex-end;
  text-align: center;
  margin: 0.5em auto;
  max-width: 77.5%;
  cursor: pointer;
}

.link-row {
 -webkit-animation: movein .25s ease-in;
         animation: movein .25s ease-in;
}
.link-row.hidden {
  -webkit-animation: moveout .25s ease-out;
          animation: moveout .25s ease-out;
}

/* Move it (define the animation) */
@-webkit-keyframes movein {
 0%   { 
  opacity: 0;
  transform: translateY(-10%); 		
 }
 100% { 
  opacity: 1;
  transform: translateY(0%); 
 }
}
@keyframes movein {
 0%   { 
  opacity: 0;
  transform: translateY(-10%); 		
 }
 100% { 
  opacity: 1;
  transform: translateY(0%); 
 }
}

/* Move it (define the animation) */
@-webkit-keyframes moveout {
 0%   { 
  opacity: 1;
  transform: translateY(0%); 		
 }
 100% { 
  opacity: 0;
  transform: translateY(10%); 
 }
}
@keyframes moveout {
 0%   { 
  opacity: 1;
  transform: translateY(0%); 		
 }
 100% { 
  opacity: 0;
  transform: translateY(10%); 
 }
}

p.TIDAL {
  padding-bottom: 9px;
}


.link-tree-button-container {
  margin: 1em auto;
  max-width: 90%;

}

.link-tree-button-link {
  color: #eee;
  text-align: center;
  text-decoration: none;
}

.link-tree-button-container .link-tree-button-text {
  color: #eee;
  font-family: 'Antic Slab', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

