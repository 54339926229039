.row,
.link-row,
.social-row {
  text-align: center;
  margin: auto;
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;

  flex-wrap: wrap;
  /* mask-image: linear-gradient(to right, transparent 0%, black 5%, black 75%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%, black 75%, transparent 100%); */
}

.social-row-icon {
  font-size: 1.25em;
  text-align: center;
  min-width: 81px;
}

.link-row .button {
  display: flex;
  align-self: center;
  margin-right: 1em;
}

.link-row.has-title {
  max-width: 70%;
}

.row-title {
  vertical-align: bottom;
  justify-content: flex-end;
  text-align: center;
  margin: 0.5em auto;
  max-width: 77.5%;
  cursor: pointer;
}

.link-row {
 animation: movein .25s ease-in;
}
.link-row.hidden {
  animation: moveout .25s ease-out;
}

/* Move it (define the animation) */
@keyframes movein {
 0%   { 
  opacity: 0;
  transform: translateY(-10%); 		
 }
 100% { 
  opacity: 1;
  transform: translateY(0%); 
 }
}

/* Move it (define the animation) */
@keyframes moveout {
 0%   { 
  opacity: 1;
  transform: translateY(0%); 		
 }
 100% { 
  opacity: 0;
  transform: translateY(10%); 
 }
}

p.TIDAL {
  padding-bottom: 9px;
}
